import React, { useState } from 'react';
import { Box, Container, Typography, Button, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CardMedia, Step, Stepper, StepLabel, makeStyles } from '@mui/material';
import LayoutType from '../../constants/layout-type';
import { KeyboardBackspace } from '@mui/icons-material';
import Colors from '../../constants/colors';
import { HeadFC } from 'gatsby';

const steps = [
    {
        short: 'Welcome',
        title: 'Welcome to Screen Keep',
        description: 'This tutorial will guide you through using Screen Keep on your TV.',
    },
    {
        short: 'Settings',
        title: 'Changing Settings',
        description: 'To access settings, double press the back button on your remote.',
    },
    {
        short: 'Navigation',
        title: 'Navigating the Settings',
        description: 'Use your remote\'s directional buttons to navigate through the app\'s interface.',
    },
    {
        short: 'URL',
        title: 'Setting Up Your URL',
        description: 'Enter the web URL you wish to display in the settings under the "Main URL" field.',
    },
    // ... Add more steps as needed
];

const TutorialDialog: React.FC<{ open: boolean, onClose: () => void, currentStep: number }> = ({ open, onClose, currentStep }) => {
    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            onClose(currentStep + 1);
        } else {
            onClose(); // Close the dialog on the last step
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            onClose(currentStep - 1);
        }
    };

    const [focusedButton, setFocusedButton] = useState<string | null>(null);

    const handleButtonFocus = (buttonName: string) => {
        setFocusedButton(buttonName);
    };

    const handleButtonBlur = () => {
        setFocusedButton(null);
    };

    return (
        <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
            <DialogTitle sx={{ textAlign: 'center', pt: 5 }}>{steps[currentStep].title}</DialogTitle>
            <DialogContent sx={{ textAlign: 'center' }}>
                {currentStep === 1 && (<></>
                    //<CardMedia
                    //    component="img"
                    //    image={backBtnAnimation}
                    //    alt="Pressing back button animation"
                    //    sx={{ width: '100%', height: 'auto', marginBottom: 2 }}
                    ///>
                )}
                <DialogContentText sx={{ display: 'inline-block', textAlign: 'center', width: '100%' }}>
                    {steps[currentStep].description}
                </DialogContentText>
                <Stepper activeStep={currentStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label.title}>
                            <StepLabel>
                                {steps[index].short} {false && (index === currentStep ? 'Current Step' : 'Completed')}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </DialogContent>
            <DialogActions>
                <Button
                    startIcon={<KeyboardBackspace />}
                    onClick={handleBack}
                    disabled={currentStep === 0}
                    onFocus={() => handleButtonFocus('back')}
                    onBlur={handleButtonBlur}
                    sx={{
                        bgcolor: focusedButton === 'back' ? Colors.SECONDARY : undefined,
                        '&:hover': {
                            bgcolor: Colors.SECONDARY,
                        },
                    }}
                >
                    Back
                </Button>
                <Button
                    onClick={handleNext}
                    onFocus={() => handleButtonFocus('next')}
                    onBlur={handleButtonBlur}
                    sx={{
                        bgcolor: focusedButton === 'next' ? Colors.SECONDARY : undefined,
                        '&:hover': {
                            bgcolor: Colors.SECONDARY,
                        },
                    }}
                >
                    {currentStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};


const WelcomePage: React.FC = () => {
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [isStartButtonFocused, setIsStartButtonFocused] = useState(false);
    const theme = useTheme();
    const isTV = useMediaQuery(theme.breakpoints.up('lg'));

    const handleStartTutorial = () => {
        setTutorialOpen(true);
        setCurrentStep(0);
    };

    const handleTutorialClose = (step?: number) => {
        if (typeof step === 'number') {
            setCurrentStep(step);
        } else {
            setTutorialOpen(false);
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100vh',
                    pr: isTV ? 8 : 2,
                    pl: isTV ? 8 : 2,
                }}
            >
                <Container maxWidth="lg">
                    <Typography variant="h2" component="h1" align="center">
                        Welcome to Screen Keep
                    </Typography>
                    <Typography variant="h6" gutterBottom align="center">
                        Your TV's companion for digital signage.
                    </Typography>
                    <Typography variant="h5" align="center" sx={{ mt: 4, mb: 8 }}>
                        Double press the back button on your remote control to configure this display.
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={handleStartTutorial}
                            onFocus={() => setIsStartButtonFocused(true)}
                            onBlur={() => setIsStartButtonFocused(false)}
                            sx={{
                                bgcolor: isStartButtonFocused ? Colors.SECONDARY : undefined,
                                '&:hover': {
                                    bgcolor: Colors.SECONDARY,
                                },
                            }}
                        >
                            Start the Tutorial
                        </Button>
                    </Box>
                </Container>
            </Box>
            <TutorialDialog open={tutorialOpen} onClose={handleTutorialClose} currentStep={currentStep} />
        </>
    );
};

WelcomePage.layoutType = LayoutType.TvDefault;

export default WelcomePage;

export const Head: HeadFC = () => <title>ScreenKeep - Welcome</title>